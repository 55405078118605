/* @import "./font.css"; */
/* commented the Arabic Font for time being. The app will fetch fallback browser fonr for Arabic */

.ar {
  font-family: 'Noto Kufi Arabic', sans-serif;
  direction: rtl;
}
.ar .productLifecycleHeader h1 {
  line-height: 65px;
}
.ar .description {
  line-height: 28px;
  font-size: 16px;
}
.ar .mapContainer,
.ar .carousel-root {
  direction: ltr;
}
.ar .selectImage {
  margin-right: 10px;
}
.ar .selectArrow {
  position: relative;
  top: 4px;
}
.ar .inputContainer label {
  direction: rtl;
  left: auto;
  right: 8px;
}
.ar .datepickerContainerHydro .date-picker-icon {
  direction: rtl;
  left: auto;
  right: 240px;
}
