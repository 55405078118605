/* @import "./font.css"; */

/* commented the Arabic Font for time being. The app will fetch fallback browser fonr for Arabic */

@import './share.css';

select {
  font-family: inherit;
}
.description {
  color: #2d2d2d;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: justify;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 328px;
}

.description ul {
  list-style: disc;
  list-style-position: outside;
  box-sizing: inherit;
  padding-left: 13px;
}

.descriptionCarasoul {
  color: #2d2d2d;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 328px;
}

.productHeader {
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
.productHeader span {
  display: block;
  color: #004480;
}
.mainImageContainer {
  margin-top: 24px;
  margin-bottom: 22px;
}
.mainImage {
  display: block;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.carrefourLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 41px;
  width: 160px;
}
.button {
  margin: 5px auto;
  display: block;
}
.productLifecycleHeader h1 {
  color: #005cad;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
}
.productLifecycleHeader {
  margin-bottom: 12px;
}

.centerContent {
  display: block;
  margin: 0px auto;
  text-align: center;
}
.rectangle {
  display: block;
  height: 1px;
  width: 328px;
  background-color: #d8d8d8;
  margin: 10px auto;
}
.combinedShape {
  display: block;
  height: 2px;
  width: 56px;
  margin: 0 auto;
  position: relative;
}
.combinedShape::before,
.combinedShape::after {
  content: '';
  border-radius: 2px;
  background-color: #005eb3;
  width: 48px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.combinedShape::after {
  width: 6px;
  margin-left: 2px;
  height: 100%;
  left: auto;
  right: 0;
}
.productSubtitleContainer {
  margin-bottom: 10px;
}
.productOraganisationContainer {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 600;
}
.certificate {
  display: block;
  margin: 10px auto;
  max-width: 120px;
  max-width: 120px;
}
.btn {
  border: 1px solid blue;
  padding: 5px;
  margin: 10px;
}

.subComponentHeader {
  justify-content: space-between;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-bottom: 18px;
  z-index: 1;
  position: relative;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.video {
  width: 100%;
}
.wrapper {
  display: table;
  width: auto;
  position: relative;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}
.play {
  background-image: url(./Play.png);
  background-repeat: no-repeat;
  width: 65px;
  height: 50px;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
}
.pause {
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
}
/* .carrefourLogoLeft {
  display: flex;
  margin: 5%;
  height: 41px;
  width: 160px;
} */
.descriptionLeft {
  margin-right: 5%;
  margin-left: 5%;
  color: #2d2d2d;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}
.subComponentHeaderLeft {
  margin: 5%;
  justify-content: space-between;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}
.productImageLandingPage {
  display: block;
  margin: 10px auto;
  width: 290px;
}
.dcr {
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
}
.outline {
  box-sizing: border-box;
  height: 48px;
  width: 296px;
  border: 1px solid #666666;
  border-radius: 2px;
  font-size: 16px;
  padding: 10px;
}

.mafsignature {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 87px;
  height: 48px;
  width: 149px;
}
.shareButton {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  width: 90%;
  margin: 0% 5%;
}

.share {
  display: flex;
  cursor: pointer;
  padding: 2px;
  justify-content: flex-end;
}

.changeLocation {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0% 5%;
  height: 35px;
  height: 40px;
  width: 360px;
  background-color: #0a437c;
}
.salesmanImg {
  height: '316px';
  width: '284px';
  margin-top: '44px';
  margin: 0% auto;
}
.addMoreCart {
  display: flex;
  flex-direction: column;
  margin: 0% auto;
  width: 80%;
}
.barcodeHeader {
  color: #2d2d2d;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.barCodeContainer {
  margin: 8%;
}
.productLogo {
  height: 35px;
  width: 133px;
  text-align: center;
}
.productLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 32px 0px 32px;
}
.barCodeDescription {
  color: #2d2d2d;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 32px;
  margin-right: 32px;
}

.deviderLine {
  height: 1px;
  width: 328px;
  margin-left: auto;
  margin-right: auto;
  background-color: #d8d8d8;
}

.barcodeImageContainer {
  margin-top: 16px;
  margin-bottom: 22px;
  width: 100%;
  text-align: center;
}
.InputText {
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  padding-bottom: 16px;
}
.inputContainer,
.selectContainer {
  margin: 16px 32px;
}
.selectContainer > div > ul {
  text-align: unset;
}
.selectContainer > div:first-child {
  background-color: #f8fafc;
  margin: 0px;
}
.selectContainer > div:first-child > div {
  background-color: unset;
}
div.en .lotNumberContainer > div > div {
  flex-direction: row-reverse;
}
div.en .lotNumberContainer > div > label {
  padding-left: 15px;
}
.centerBtn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 40px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.container {
  max-width: 360px;
  margin: 0% auto;
}

.productImageContainer {
  margin-top: 44px;
  margin-bottom: 32px;
}
.carouselContainer {
  background: #ffffff;
  position: relative;
  top: -90px;
  /* overflow: hidden; */
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  height: 700px;
}
.carouselContainerWithouMap {
  background: #ffffff;
  position: relative;
  top: -110px;
  /* overflow: hidden; */
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  height: 325px;
}
.carouselContainer1 {
  background: #ffffff;
  position: relative;
  overflow: hidden;
}
.salesHeader {
  color: #333333;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
}
.sharebtntext {
  color: #000000;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #4d4d4d;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
}
/* #### Tablets Portrait or Landscape #### */
/* @media screen and (min-device-width: 600px) {
  .carouselContainer {
    background: #fff;
    margin: 0% auto;
  }
  .canvasComponent {
    height: 400px;
    width: 500px;
    margin: 0% auto;
  }
  .scanAnothotherProductBtn {
    margin: 0 auto;
  }
} */

.certificateContainer {
  width: 360px;
  margin: 16px auto;
}

.backBtn {
  margin: 15px auto;
}

.batchcodeContainer {
  max-width: 360px;
  margin: 0% auto;
}

.batchcodeContainer .shareContainer {
  margin-left: 0px;
  margin-right: 0px;
}

.errorStart {
  color: red;
  text-align: start;
  margin-left: 32px;
  margin-right: 32px;
}

.errorCenter {
  color: red;
  text-align: center;
  margin-left: 32px;
  margin-right: 32px;
}

.mapContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 480px;
  z-index: 1;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
}
.mapContainer1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 150px;
  z-index: 1;
}
.fakeMapContainer {
  width: 1200px;
  position: absolute;
  left: -422px;
  top: -230px;
  margin: 0px auto;
  margin-left: auto;
  margin-right: auto;
}
.mapPin {
  position: absolute;
  left: 221px;
  bottom: 115px;
  height: 66px;
  background: #000;
  width: 1px;
}
.mapPin::before {
  content: '';
  position: absolute;
  top: -14px;
  left: -6px;
  width: 14px;
  height: 14px;
  border: 3px solid #000;
  border-radius: 100px;
}
.mapText {
  font-size: 24px;
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 225px;
  font-weight: 700;
  line-height: 31px;
}
.mapText span {
  font-weight: 500;
  display: block;
  font-size: 16px;
  margin-left: 70px;
}
.mapText div {
  margin-left: 70px;
}
.scanAnothotherProductBtn a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}
.iconComponent {
  width: 120px;
  margin: 0 auto;
  margin-bottom: 15px;
  position: relative;
}

.iconComponent img {
  width: 100%;
}

.rearingLine {
  position: absolute;
  width: 300px;
  height: 3px;
  left: 100px;
  top: 142px;
  background-image: url(../enums/dotted-line.svg);
  background-size: 20px 3px;
  background-repeat: repeat-x;
  transform: rotate(23deg);
}
.stepsHolder {
  overflow: hidden;
  position: relative;
}
.stepsHolder.overflowVisible {
  overflow: visible;
}
.mapDots {
  position: absolute;
  width: 300px;
  height: 2px;
  left: 100px;
  top: 142px;
  background-image: url(../enums/dotted-line.svg);
  background-size: 15px 100%;
  background-repeat: repeat-x;
  transform: rotate(22deg);
}
.beans1 {
  position: absolute;
  left: 0;
  top: 160px;
  width: 100px;
  height: 80px;
  background: transparent url('../enums/beans-1.png') no-repeat center center;
  background-size: 100% auto;
}
.beans2 {
  position: absolute;
  left: 0px;
  top: 270px;
  width: 60px;
  height: 45px;
  background: transparent url('../enums/beans-2.png') no-repeat center center;
  background-size: 100% auto;
}
.beans3 {
  position: absolute;
  right: 20px;
  top: 89px;
  width: 40px;
  height: 40px;
  background: transparent url('../enums/beans-3.png') no-repeat left top;
  background-size: 100% auto;
}
.beans4 {
  position: absolute;
  right: -19px;
  top: 60px;
  width: 40px;
  height: 40px;
  background: transparent url('../enums/beans-4.png') no-repeat left top;
  background-size: 100% auto;
}
.beans5 {
  position: absolute;
  right: 0px;
  top: 190px;
  width: 40px;
  height: 50px;
  background: transparent url('../enums/beans-5.png') no-repeat left top;
  background-size: 28px auto;
}
.posRelative {
  position: relative;
}
.carousel-root {
  position: relative;
  z-index: 8;
  top: 105px;
}
.productTextDescription {
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  max-width: 328px;
  margin-left: auto;
  margin-right: auto;
}

.roundImage {
  width: 260px;
  height: 260px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;
  border: 5px solid #fff;
  box-shadow: 0px -2px 5px 0px #bfbfbf;
  background-color: #ffffff;
}
.carousel .slide img.rectImage {
  width: auto;
  margin: 0 auto;
  border: 0;
  box-shadow: none;
}
.carouselContainerWithouMap.rectCarousel {
  height: 100%;
}
.rectCarousel .mapContainer1 {
  height: 70px;
}
.carasoulRoundImg .roundImage {
  width: 295px;
  height: 295px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;
  border: 5px solid #fff;
  box-shadow: 0px -2px 5px 0px #bfbfbf;
  background-color: #ffffff;
}
.carouselContainer .carousel.carousel-slider {
  overflow: visible;
}
.carouselContainer .carousel .slide {
  background: transparent;
  padding-top: 10px;
}
.carouselContainer .carousel .control-dots {
  bottom: -40px;
}
.carouselContainer .carousel .control-dots .dot.selected {
  width: 18px;
  border-radius: 4px;
  opacity: 1;
  background-color: #ffffff;
}
.carouselContainerWithouMap .carousel.carousel-slider {
  overflow: visible;
}
.carouselContainerWithouMap .carousel .slide {
  background: transparent;
  padding-top: 10px;
}
.carouselContainerWithouMap .carousel .control-dots {
  bottom: -40px;
}
.carouselContainerWithouMap .carousel .control-dots .dot.selected {
  width: 18px;
  border-radius: 4px;
  opacity: 1;
}
.carouselWithoutMap .carouselContainerWithouMap .carousel .control-dots .dot.selected {
  width: 18px;
  border-radius: 4px;
  opacity: 1;
  background-color: #0a437c;
}
.carouselContainerWithouMap .carousel .slide {
  background: transparent;
  padding-top: 10px;
}
.carouselContainer .carousel .control-dots .dot {
  opacity: 0.5;
  box-shadow: none;
  margin: 0 4px;
  background-color: #ffffff !important;
}
.carouselWithoutMap .carouselContainer .carousel .control-dots .dot {
  opacity: 0.5;
  box-shadow: none;
  margin: 0 4px;
  background-color: #005eb3;
}
.carousel-status {
  display: none;
}
.certificateBlock {
  margin-bottom: 25px;
}
.dottedLine {
  position: absolute;
  left: 0;
  top: 82px;
  width: 300px;
  overflow: hidden;
  z-index: -1;
}
.dottedLine2 {
  left: -154px;
  top: -382px;
  width: 400px;
  height: 600px;
}
.dottedLine3 {
  right: 8px;
  top: -83px;
  width: 318px;
  height: 213px;
  left: auto;
  transform: rotate(10deg);
}
.dottedLine4 {
  right: 0;
  top: 55px;
  width: 360px;
  height: 310px;
  left: auto;
  z-index: 1;
}
.dottedLine4 img {
  width: 100%;
}
.dottedline-even {
  left: -100%;
  top: -138px;
  width: 360px;
  height: 310px;
  -moz-transform: rotate(55deg);
  -webkit-transform: rotate(55deg);
  -o-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  transform: rotate(55deg);
  z-index: -1;
}
.dottedline.flipped {
  -moz-transform: rotate(50deg);
  -webkit-transform: rotate(50deg);
  -o-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  transform: rotate(50deg);
}
.dottedline-even:not(.flipped) {
  -moz-transform: rotate(17deg);
  -webkit-transform: rotate(17deg);
  -o-transform: rotate(17deg);
  -ms-transform: rotate(17deg);
  transform: rotate(17deg);
}
.iconComponent {
  background-color: #fff;
}
.dottedline-even img {
  width: 100%;
}
.dottedLine::before {
  content: '';
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 1200ms ease;
  -moz-transition: all 1200ms ease;
  -ms-transition: all 1200ms ease;
  -o-transition: all 1200ms ease;
  transition: all 1200ms ease;
}
.dottedLine2::before,
.dottedLine4::before,
.dottedline-even::before {
  left: 0;
  bottom: 0;
  right: auto;
  top: auto;
}
.dottedline-even.flipped::before {
  right: 0;
  left: auto;
}
.dottedLine1.active::before,
.dottedLine3.active::before,
.dottedline-even.active::before {
  width: 0;
}
.dottedLine2.active::before,
.dottedLine4.active::before {
  height: 0;
}
.pdfContainer {
  position: relative;
}
.pdfloader {
  text-align: center;
}
.carouselWithoutMap {
  margin-top: 0px;
}
.date-picker-input {
  /* box-sizing: border-box;
  height: 48px;
  width: 296px;
  border: 1px solid #666666;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 10px; */
  background: transparent;
  display: block;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #0e5aa7;
  padding: 8px;
  width: 296px;
  background-color: #f8fafc;
  font-size: 16px;
  line-height: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #666666;
}

.red-date-picker {
  color: red;
  border-color: red;
}

.popup-close-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.popup-msg-body {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}

.incorrect-input {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #f34041;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: -15px;
}

.closeImgContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
}
input.ltr-jb7bqi {
  background-color: #f8fafc;
  padding: 12px;
}
label.ltr-9bgi9v {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: #f8fafc;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #666666;

  box-sizing: border-box;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
}

button.outline.ltr-11iyadn {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #b2b2b2;
}

.productSubtitleContainer h3 {
  letter-spacing: 1px;
}

.table {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
}
.table .thtd {
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  padding: 0.5rem;
  height: 35px;
  border-radius: 2px 2px 0 0;
  color: #4d4d4d;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}
.table .thtd.thKey {
  width: 40%;
}
.table .thtd.thValue {
  width: 60%;
}

.table .thtd .test {
  color: #2d2d2d;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}
.LayerUp {
  position: relative;
  z-index: 1;
}
.animated.fadeIn {
  position: relative;
  z-index: 1;
}
.overflowHidden {
  overflow: hidden;
}
.storeName {
  color: #000000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
.centerStoreInfo {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.storeInfoContainer {
  margin-top: 32px;
  margin-bottom: 24px;
}
.dateBox {
  width: 172px;
  border-radius: 20px;
  background-color: #0e5aa7;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}
.dateBoxFull {
  height: 40px;
  border-radius: 20px;
  background-color: #0e5aa7;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 7px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.dateText {
  width: 132px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.storeKeyText {
  color: #4d4d4d;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
.storeNameSpacing {
  margin-bottom: 8px;
}

.mapTextCenter {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.carouselWithoutMap .dottedLine {
  left: -22px;
  top: 35px;
  transform: rotate(13deg);
}
.dateTextAr {
  height: 24px;
  width: 132px;
  color: #ffffff;
  font-family: 'Noto Kufi Arabic';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}
.errorMsg {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* or 150% */

  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 328px;
}
.inputContainerDate {
  margin-bottom: 24px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
  background-color: #f3f5f8;
}
.dateInputText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding-left: 9px;
  color: #2d2d2d;
}

.DatePickerHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}
.date-picker-icon {
  float: right;
  margin-right: 22px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}

.productImageContainerHydo {
  margin-top: 24px;
  margin-bottom: 24px;
}
.datepickerContainerHydro {
}
.datepickerContainerHydro .react-datepicker__input-container input {
  background: transparent;
  display: block;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #0e5aa7;
  padding: 8px;
  width: 296px;

  font-size: 16px;
  line-height: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.productImageContainerBottom {
  margin-bottom: 32px;
}
.maplocation {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.carousel .control-dots .dot {
  background-color: #005cad !important;
}
canvas {
  width: 100% !important;
  height: auto !important;
}
.pdfContainer
  .Example__container__document
  .react-pdf__Document
  .react-pdf__Page
  .react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.pdfContainer .react-pdf__Page__textContent {
  position: fixed !important;
}
