.shareContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
}
.social {
  position: absolute;
  top: 10px;
  right: 0;
  margin: 15px auto;
}
.selectDropdown {
  border: none;
  -webkit-appearance: none;
  width: 75px;
  padding-left: 2px;
  padding-right: 2px;
  justify-content: center;
  margin-left: 2px;
  margin-right: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.selectImage {
  height: 16px;
  width: 16px;
  margin-left: 2px;
  margin-right: 6px;
  float: left;
}
.btnStyleSocial {
  display: block;
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  color: #000000;
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
}
.pdfContainer .react-pdf__Page__textContent {
  width: 100% !important;
  height: auto !important;
}
