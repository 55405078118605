body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.maplocation.efp-oman-eggs {
  margin-top: 90px;
  margin-left: -20px;
}

.maplocation.egypt-grapes {
  margin-top: -40px;
  margin-left: -130px;
}

.maplocation.agrico-mushroom {
  margin-top: -70px;
  margin-left: 16px;
}

.maplocation.gipf-oman-eggs {
  margin-top: 54px;
  margin-left: 102px;
}

.carouselContainer.efp-oman-eggs .carousel .control-dots .dot {
  background-color: #000 !important;
}
