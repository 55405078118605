.barcodeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e8e8e8;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.continueButton {
  background-color: black;
  margin: 10px;
}
.imageContainer {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  margin-bottom: 5%;
}
.loading {
  position: fixed;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999999999999;
}
