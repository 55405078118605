body {
  font-family: 'Arial', sans-serif;
  background-color: white;
}

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 16px;
  padding: 8px;
  width: 50%;
  flex-direction: column;
  display: flex;
  cursor: pointer;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}
.Demo__container {
  box-sizing: border-box;
  min-width: 0px;
  color: rgb(77, 77, 77);
  flex-flow: row wrap;
  background-color: rgb(255, 255, 255);
  width: 264px;
  display: flex;
  right: 0px;
  border-radius: 6px;
  z-index: 2;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;
}
.icon_name_holder {
  margin: 0px;
  min-width: 0px;
  display: inline-flex;
}
.icon_name_holder span {
  font-size: 14px;
  align-self: center;
}
.icon_name_holder svg {
  margin: 0 6px;
}
